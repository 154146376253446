














import { Component, Vue, Prop } from 'vue-property-decorator';

import Front from './Front/Front.vue';
import Back from './Back/Back.vue';

import { Card as ICard } from '../../interface/card';

@Component({
  components: {
    Front,
    Back
  }
})
export default class Card extends Vue {
  @Prop() card!: ICard;

  private isFlipped = false;

  get frontBackgroundStyle() {
    const primaryColor = this.card.color || '#28CAF3';

    const adjustColor = (color: string) => {
      const hexToRgb = (hex: string) => {
        const bigint = parseInt(hex.slice(1), 16);
        // eslint-disable-next-line no-bitwise
        const r = (bigint >> 16) & 255;
        // eslint-disable-next-line no-bitwise
        const g = (bigint >> 8) & 255;
        // eslint-disable-next-line no-bitwise
        const b = bigint & 255;
        return { r, g, b };
      };

      const rgbToHsl = ({ r, g, b }: { r: number; g: number; b: number }) => {
        const rNorm = r / 255;
        const gNorm = g / 255;
        const bNorm = b / 255;
        const max = Math.max(rNorm, gNorm, bNorm);
        const min = Math.min(rNorm, gNorm, bNorm);

        let h = 0;
        let s = 0;
        const l = (max + min) / 2;

        if (max !== min) {
          const d = max - min;
          s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
          if (max === rNorm) h = (gNorm - bNorm) / d + (gNorm < bNorm ? 6 : 0);
          else if (max === gNorm) h = (bNorm - rNorm) / d + 2;
          else h = (rNorm - gNorm) / d + 4;
          h /= 6;
        }

        return { h: h * 360, s: s * 100, l: l * 100 };
      };

      const hslToHex = (h: number, s: number, l: number) => {
        const sAdjusted = s / 100;
        const lAdjusted = l / 100;
        const k = (n: number) => (n + h / 30) % 12;
        const a = sAdjusted * Math.min(lAdjusted, 1 - lAdjusted);
        const f = (n: number) => Math.round(
          255 * (lAdjusted - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1))))
        );
        return `#${f(0)
          .toString(16)
          .padStart(2, '0')}${f(8)
          .toString(16)
          .padStart(2, '0')}${f(4)
          .toString(16)
          .padStart(2, '0')}`;
      };

      const rgb = hexToRgb(color);
      const hsl = rgbToHsl(rgb);

      // Ajuste da cor secundária (redução de luminosidade e leve alteração na saturação)
      hsl.l *= 0.8; // Reduz luminosidade para 80% do valor original
      hsl.s *= 0.9; // Reduz saturação para 90% do valor original

      return hslToHex(hsl.h, hsl.s, hsl.l);
    };

    const secondaryColor = adjustColor(primaryColor);

    return {
      background: `linear-gradient(to top, ${secondaryColor}, ${primaryColor})`
    };
  }

  handleToggleFlipped() {
    this.isFlipped = !this.isFlipped;
  }
}
