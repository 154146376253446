























import { Component, Prop, Vue } from 'vue-property-decorator';

const DEFAULT_FILL = '#FBFBFB';

@Component
export default class AirForce extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
  @Prop({ default: '24' }) width!: string;
  @Prop({ default: '24' }) height!: string;
}
