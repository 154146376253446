





























import { Component, Vue, Prop } from 'vue-property-decorator';
import Soldiers from '@/assets/icons/war/Soldiers.vue';
import AirForce from '@/assets/icons/war/AirForce.vue';
import Vehicles from '@/assets/icons/war/Vehicles.vue';
import Navy from '@/assets/icons/war/Navy.vue';
import Resources from '@/assets/icons/war/Resources.vue';
import Pin from '@/assets/icons/war/Pin.vue';

import { CardCountry } from '@/globalInterfaces/War';

@Component({
  components: {
    Soldiers,
    AirForce,
    Vehicles,
    Navy,
    Resources,
    Pin
  }
})
export default class MapHover extends Vue {
  @Prop({ required: true }) readonly country!: CardCountry;

  get countryName() {
    return this.country.countryName ? `${this.country.countryName}` : 'País Desconhecido';
  }

  get soldiers() {
    return this.country.soldiers ? `${this.country.soldiers}` : 0;
  }

  get airForce() {
    return this.country.airForce ? `${this.country.airForce}` : 0;
  }

  get vehicles() {
    return this.country.vehicles ? `${this.country.vehicles}` : 0;
  }

  get navy() {
    return this.country.navy ? `${this.country.navy}` : 0;
  }

  get resources() {
    return this.country.resources ? `${this.country.resources}` : 0;
  }
}
