















import { Component, Vue, Prop } from 'vue-property-decorator';

import Header from '../Header/Header.vue';

import { Card } from '../../../interface/card';

@Component({
  components: {
    Header
  }
})
export default class Front extends Vue {
  @Prop() card!: Card;
  @Prop() background!: string;
}
