





































































import { Component, Prop, Vue } from 'vue-property-decorator';
import LogoExplicae from '@/assets/imgs/logo/LogoExplicae.vue';
import Soldiers from '@/assets/icons/war/Soldiers.vue';
import AirForce from '@/assets/icons/war/AirForce.vue';
import Vehicles from '@/assets/icons/war/Vehicles.vue';
import Navy from '@/assets/icons/war/Navy.vue';
import Resources from '@/assets/icons/war/Resources.vue';

import { TypeWorldCard } from '@/globalInterfaces/War';

@Component({
  components: {
    LogoExplicae,
    Soldiers,
    AirForce,
    Vehicles,
    Navy,
    Resources
  }
})
export default class ExpandedCard extends Vue {
  @Prop({ default: {} }) wordCard!: TypeWorldCard;

  get soldiers() {
    return this.wordCard.soldiers ? `${this.wordCard.soldiers}` : 0;
  }

  get airForce() {
    return this.wordCard.airForce ? `${this.wordCard.airForce}` : 0;
  }

  get vehicles() {
    return this.wordCard.vehicles ? `${this.wordCard.vehicles}` : 0;
  }

  get navy() {
    return this.wordCard.navy ? `${this.wordCard.navy}` : 0;
  }

  get resources() {
    return this.wordCard.resources ? `${this.wordCard.resources}` : 0;
  }
}
