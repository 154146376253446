



























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Info extends Vue {
  @Prop({ default: '29' }) width!: string;
  @Prop({ default: '32' }) height!: string;
}
