







import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Header extends Vue {
  @Prop({ required: true }) private text!: string;
  @Prop({ required: true }) private color!: string;
}
