















import { Component, Prop, Vue } from 'vue-property-decorator';

const DEFAULT_FILL = '#CCF241';

@Component
export default class Planet extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
  @Prop({ default: '24' }) width!: string;
  @Prop({ default: '24' }) height!: string;
}
