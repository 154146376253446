


















import { Component, Vue, Prop } from 'vue-property-decorator';

import Soldiers from '@/assets/icons/war/Soldiers.vue';
import AirForce from '@/assets/icons/war/AirForce.vue';
import Vehicles from '@/assets/icons/war/Vehicles.vue';
import Navy from '@/assets/icons/war/Navy.vue';
import Resources from '@/assets/icons/war/Resources.vue';

import { LIST_ATTRIBUTES } from './constants';

import { Attributes as IAttributes, AttributesProp } from './interface';

@Component({
  components: {
    Soldiers,
    AirForce,
    Vehicles,
    Navy,
    Resources
  }
})
export default class Attributes extends Vue {
  @Prop() attributes!: AttributesProp;

  get listAttributes() {
    const list = LIST_ATTRIBUTES.map((attribute: IAttributes) => ({
      ...attribute,
      value: this.attributes[attribute.key]
    }));

    return list;
  }
}
