






















import { Component, Vue, Prop } from 'vue-property-decorator';

import RotateIcon from '@/assets/icons/war/Rotate.vue';

@Component({
  components: {
    RotateIcon
  }
})
export default class Header extends Vue {
  @Prop() title!: string;
  @Prop() country!: string;
  @Prop() image!: string;
}
