


















import { Component, Vue, Prop } from 'vue-property-decorator';

import LogoExplicae from '@/assets/imgs/logo/LogoExplicae.vue';

import Header from '../Header/Header.vue';
import Attributes from '../Attributes/Attributes.vue';

import { Card } from '../../../interface/card';

@Component({
  components: {
    Header,
    Attributes,
    LogoExplicae
  }
})
export default class Back extends Vue {
  @Prop() card!: Card;
  @Prop() background!: string;

  get attributes() {
    const attribute = {
      soldiers: this.card.soldiers,
      navy: this.card.navy,
      airForce: this.card.airForce,
      vehicles: this.card.vehicles,
      resources: this.card.resources
    };

    return attribute;
  }
}
