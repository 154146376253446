import { Attributes } from '../interface';

export const LIST_ATTRIBUTES: Attributes[] = [
  {
    name: 'Soldados ativos:',
    value: 0,
    key: 'soldiers',
    component: 'Soldiers'
  },
  {
    name: 'Força aérea:',
    value: 0,
    key: 'airForce',
    component: 'AirForce'
  },
  {
    name: 'Blindados:',
    value: 0,
    key: 'vehicles',
    component: 'Vehicles'
  },
  {
    name: 'Frota marinha:',
    value: 0,
    key: 'navy',
    component: 'Navy'
  },
  {
    name: 'Recursos:',
    value: 0,
    key: 'resources',
    component: 'Resources'
  }
];
