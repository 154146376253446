

























































































































































































import { Component, Vue } from 'vue-property-decorator';

import Menu from '../../components/Menu/Menu.vue';
import Background from '../../components/Background/Background.vue';
import WorldMap from '@/assets/icons/war/WorldMap.vue';
import MapLabel from '../../components/MapLabel/MapLabel.vue';
import Card from '../../components/Card/Card.vue';
import WorldCard from '../../components/WorldCard/WorldCard.vue';
import MapHover from '../../components/MapHover/MapHover.vue';
import Flag from '@/assets/icons/war/Flag.vue';
import Badge from '@/assets/icons/war/Badge.vue';
import ExpandedCard from './components/ModalCard/ExpandedCard.vue';
import AttackIcon from '@/assets/icons/war/Attack.vue';
import HelmetSidewaysIcon from '@/assets/icons/war/HelmetSideways.vue';
import StarsLeft from '@/assets/icons/war/StarsLeft.vue';
import StarsRight from '@/assets/icons/war/StarsRight.vue';
import FeedbackErrorLeft from '@/assets/icons/war/FeedbackErrorLeft.vue';
import FeedbackErrorRight from '@/assets/icons/war/FeedbackErrorRight.vue';
import Modal from './components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import Info from '@/assets/icons/war/Info.vue';
import Cards from '@/assets/icons/war/Cards.vue';

import { Card as ICard } from '../../interface/card';
import { ListCountry } from '../../interface/country';
import { CardCountry, TypeWorldCard } from '@/globalInterfaces/War';
import { CountryResponse, ResponseCountryUser } from '@/services/War/WarInterface';

import WarService from '@/services/War/WarService';

import { sleep } from '@/share/Util/UsefulFunctions/UserfulFunctions';

import { DEFAULT_CARD } from '../../constants/index';
import { NAME_ROUTES_CONQUISTAE } from '@/router/routes/War';

@Component({
  components: {
    Menu,
    Background,
    WorldMap,
    MapLabel,
    Card,
    WorldCard,
    MapHover,
    Flag,
    Badge,
    ExpandedCard,
    AttackIcon,
    HelmetSidewaysIcon,
    StarsLeft,
    StarsRight,
    FeedbackErrorLeft,
    FeedbackErrorRight,
    Modal,
    Button,
    Info,
    Cards
  }
})
export default class WarRoom extends Vue {
  selectedCountry: string | null = null;
  hoverCountry: CardCountry | null = null;
  mousePosition = { x: 0, y: 0 };
  modalCountry = false;
  modalCountryAtackFeedback = false;
  modalBaseCountry = false;
  isActionRunning = false;
  isActionSuccess = false;
  countryStatus = '';
  containerRect: DOMRect | null = null;
  mapRect: DOMRect | null = null;
  minX = 0;
  maxX = 0;
  minY = 0;
  maxY = 0;

  // Dados do país selecionado
  selectedCountryData: ICard = DEFAULT_CARD;

  feedbackStatus: 'attackFailed' | 'attackSuccess' | null = null;

  // Importa os países conquistados do arquivo JSON
  // conqueredCountries = [
  //   {
  //     id: 'brazil',
  //     neighbors: [
  //       'uruguay',
  //       'argentina',
  //       'paraguay',
  //       'bolivia',
  //       'peru',
  //       'colombia',
  //       'venezuela',
  //       'guyana',
  //       'suriname',
  //       'french_guiana',
  //     ],
  //   },
  // ];

  isDragging = false;
  dragStart = { x: 0, y: 0 };
  mapPosition = { x: 0, y: 0 };
  lastMapPosition = { x: 0, y: 0 };
  mapScale = 1;

  isLoading = true;

  warService = new WarService();

  async mounted() {
    await this.getCountries();
    await this.loadMap();
  }

  get countriesStore(): CountryResponse[] {
    return this.$store.getters['War/countries'];
  }

  get countriesUser() {
    return this.$store.getters['War/countriesUser'];
  }

  get cardUser(): TypeWorldCard {
    return this.$store.getters['War/cardUser'];
  }

  get conqueredCountriesCount() {
    return this.$store.getters['War/conqueredCountriesCount'];
  }

  get listCountries(): ListCountry[] {
    const countriesList: ListCountry[] = [];
    this.countriesStore.forEach((itemCountry: CountryResponse) => {
      const objCountry = itemCountry;
      const card = objCountry.card.ID ? objCountry.card : DEFAULT_CARD;
      const countryData = {
        id: objCountry.ID,
        slug: objCountry.slug,
        neighbors: objCountry.associations.map((countryId: number) => this.countriesStore.find((resCountry: CountryResponse) => resCountry.ID === countryId)?.slug),
        card: {
          ...card,
          country: {
            ID: objCountry.ID,
            categoryID: objCountry.categoryID,
            categoryName: objCountry.categoryName,
            title: objCountry.title,
            image: objCountry.image,
            status: objCountry.status
          }
        }
      };

      countriesList.push(countryData as ListCountry);
    });
    return countriesList;
  }

  async getCountries() {
    try {
      this.isLoading = true;

      const response = await this.warService.countries();

      const lisCountry = response.map((country) => ({ ...country.country }));

      this.$store.commit('War/setCountries', lisCountry);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar paises.',
        status: 'error'
      });
    } finally {
      this.isLoading = false;
    }
  }

  async loadMap() {
    await this.getCountriesUser();
    await this.getCardUser();
    this.setupCountryInteractions();
    this.setupCountryFilters();
  }

  async getCountriesUser() {
    try {
      this.isLoading = true;

      const response = await this.warService.countriesUsers();
      const userCountries = response.map((countryUser) => ({
        ...countryUser,
        slug: this.countriesStore.find((item: CountryResponse) => item.ID === countryUser.countryID)?.slug
      }));

      this.$store.commit('War/setCountriesUser', userCountries);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar paises conquistados.',
        status: 'error'
      });
    } finally {
      this.isLoading = false;
    }
  }

  async getCardUser() {
    try {
      this.isLoading = true;

      const response = await this.warService.cardUser();

      this.$store.commit('War/setCardUser', response.card);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  async handleAttack() {
    try {
      this.isActionRunning = true;

      await this.warService.countryAtack(this.selectedCountryData.country.ID);
      await this.loadMap();

      await sleep(1800);

      this.isActionRunning = false;
      this.isActionSuccess = false;

      this.feedbackStatus = 'attackSuccess';
    } catch (error) {
      await sleep(1800);
      this.isActionSuccess = false;
      this.isActionRunning = false;

      this.feedbackStatus = 'attackFailed';
    } finally {
      this.modalCountry = false;
      this.modalCountryAtackFeedback = true;
    }
  }

  handleReinforce() {
    this.$router.push({ name: NAME_ROUTES_CONQUISTAE.TRAINING_CENTER });
  }

  handleCheckPowerAtack(victim: ICard) {
    const soldiersSuccess = this.cardUser.soldiers >= victim.soldiers;
    const airForceSuccess = this.cardUser.airForce >= victim.airForce;
    const vehiclesSuccess = this.cardUser.vehicles >= victim.vehicles;
    const navySuccess = this.cardUser.navy >= victim.navy;
    const resourcesSuccess = this.cardUser.resources >= victim.resources;

    return soldiersSuccess && airForceSuccess && vehiclesSuccess && navySuccess && resourcesSuccess;
  }

  setupCountryFilters() {
    const countryElements = document.querySelectorAll('.country');
    const conqueredCountries = this.countriesUser.map((country: ResponseCountryUser) => country.countryID);
    const allCountriesConqueredIds = [...conqueredCountries, this.cardUser.country.ID];
    const allCountriesConqueredSlugs = allCountriesConqueredIds.map((id: number) => this.countriesStore.find((country: CountryResponse) => country.ID === id)?.slug);
    const allNeighbors = this.listCountries.filter((country: ListCountry) => allCountriesConqueredSlugs.includes(country.slug));
    const allNeighborsSlugs = allNeighbors.map((country: ListCountry) => country.neighbors).flat();

    countryElements.forEach((country) => {
      const countrySlug = country.id;
      const countryData: CountryResponse = this.countriesUser.find((c: CountryResponse) => c.slug === countrySlug);
      const countryItem = this.countriesStore.find((c: CountryResponse) => c.slug === countrySlug);
      const initialCountry = countryItem && this.cardUser.country.ID === countryItem.ID;

      if (allCountriesConqueredSlugs.includes(countrySlug)) {
        country.classList.add('conquered');
      }
      if (allNeighborsSlugs.includes(countrySlug) && !countryData && !initialCountry && !conqueredCountries.includes(countryItem?.ID)) {
        country.classList.add('neighbor');
      }
      if (initialCountry) {
        country.classList.add('initial');
      }
    });
  }

  setupCountryInteractions() {
    // Seleciona todos os elementos com a classe 'allPaths'
    const countryElements = document.querySelectorAll('.country');

    countryElements.forEach((country) => {
      country.addEventListener('mouseenter', this.handleCountryHover);
      country.addEventListener('mouseleave', this.handleCountryLeave);
      country.addEventListener('click', this.handleCountryClick);
      country.addEventListener('mousemove', this.handleMouseMove);
    });
  }

  handleMouseMove(event: Event) {
    const mouseEvent = event as MouseEvent;
    this.mousePosition = {
      x: mouseEvent.clientX,
      y: mouseEvent.clientY
    };
  }

  handleCountryHover(event: Event) {
    const mouseEvent = event as MouseEvent;
    const country = mouseEvent.target as SVGElement;

    const countrySlug = country.id;
    const countryData = this.listCountries.find((c) => c.slug === countrySlug);

    this.hoverCountry = {
      ...(countryData?.card || {}),
      countryName: countryData?.card.country.title || '',
      ID: countryData?.card?.ID || 0
    } as CardCountry;

    this.mousePosition = {
      x: mouseEvent.clientX,
      y: mouseEvent.clientY
    };
  }

  handleCountryLeave() {
    this.hoverCountry = null;
  }

  handleCountryClick(event: Event) {
    try {
      if (this.isDragging) {
        this.isDragging = false;
        return;
      }

      const mouseEvent = event as MouseEvent;
      let target = mouseEvent.target as SVGElement;

      while (target && !target.classList.contains('country')) {
        target = (target.parentElement as unknown) as SVGElement;
        if (!target) return;
      }

      if (target) {
        const countrySlug = target.id;
        this.selectedCountry = countrySlug;

        const countryData = this.listCountries.find((c) => c.slug === countrySlug);
        this.selectedCountryData = {
          ID: countryData?.card?.ID ?? 0,
          description: countryData?.card?.description ?? '',
          title: countryData?.card?.title ?? '',
          image: countryData?.card?.image ?? '',
          color: countryData?.card?.color ?? '',
          airForce: countryData?.card?.airForce ?? 0,
          navy: countryData?.card?.navy ?? 0,
          soldiers: countryData?.card?.soldiers ?? 0,
          vehicles: countryData?.card?.vehicles ?? 0,
          resources: countryData?.card?.resources ?? 0,
          created_date: countryData?.card?.created_date ?? '',
          updated_date: countryData?.card?.updated_date ?? '',
          status: countryData?.card?.status ?? 0,
          country: countryData?.card?.country ?? {
            ID: 0,
            categoryID: 0,
            categoryName: '',
            title: '',
            image: '',
            status: 0
          }
        };

        const greaterPower = this.handleCheckPowerAtack(this.selectedCountryData);

        this.modalCountry = true;

        if (target.classList.contains('neighbor') && !target.classList.contains('conquered')) {
          if (greaterPower) {
            this.countryStatus = 'available';
          } else {
            this.countryStatus = 'insufficientTroops';
          }

        } else if (target.classList.contains('initial') || target.classList.contains('conquered')) {
          this.countryStatus = 'conquered';
        } else {
          this.countryStatus = 'blocked';
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  handleOpenBaseModal() {
    this.modalBaseCountry = true;
  }

  handleCloseBaseModal() {
    this.modalBaseCountry = false;
  }

  handleCloseModalCountry() {
    this.modalCountry = false;
    this.selectedCountry = null;
  }

  handleCloseModalCountryAtack() {
    this.modalCountryAtackFeedback = false;
    this.selectedCountry = null;
    this.feedbackStatus = null;
  }

  handleTouchStart(event: TouchEvent) {
    if (event.touches.length === 1) {
      this.isDragging = true;
      this.dragStart = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY
      };
      this.lastMapPosition = { ...this.mapPosition };

      const container = this.$refs.mapContainer as HTMLElement;
      const map = (this.$refs.worldMap as any).$el as HTMLElement;
      this.containerRect = container.getBoundingClientRect();
      this.mapRect = map.getBoundingClientRect();
      this.minX = this.containerRect.width - this.mapRect.width;
      this.maxX = 0;
      this.minY = this.containerRect.height - this.mapRect.height;
      this.maxY = 0;
    }
  }

  handleTouchMove(event: TouchEvent) {
    if (!this.isDragging) return;

    if (event.touches.length === 1) {
      const touch = event.touches[0];
      let proposedX = this.lastMapPosition.x + (touch.clientX - this.dragStart.x);
      let proposedY = this.lastMapPosition.y + (touch.clientY - this.dragStart.y);

      window.requestAnimationFrame(() => {
        if (proposedX < this.minX) proposedX = this.minX;
        if (proposedX > this.maxX) proposedX = this.maxX;
        if (proposedY < this.minY) proposedY = this.minY;
        if (proposedY > this.maxY) proposedY = this.maxY;

        this.mapPosition = { x: proposedX, y: proposedY };
      });
    }
  }

  handleTouchEnd() {
    this.isDragging = false;
    this.lastMapPosition = { ...this.mapPosition };
  }

  handleOpenLabels() {
    const container = this.$refs.containerLabels as HTMLElement;
    container.classList.toggle('--opened');
  }

  handleWorldCard() {
    const worldCard = (this.$refs.worldCard as Vue).$el as HTMLElement;
    worldCard.classList.toggle('--opened');
  }
}
