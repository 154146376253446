











import { Prop, Vue, Component } from 'vue-property-decorator';

@Component
export default class Button extends Vue {
  @Prop({ default: 'center' }) align!: 'left' | 'right' | 'center';
  @Prop() gap!: string;

  get style() {
    const styles = {
      left: 'flex-start',
      right: 'flex-end',
      center: 'center'
    };

    return styles[this.align];
  }
}
